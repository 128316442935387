// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disableTextSelection{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.fullScreen{position:fixed;top:0;left:0;bottom:0;right:0;z-index:10}.fullHeight{height:100%}.rcsbFvMain *{box-sizing:border-box}.rcsbFvMain{width:100%;height:100%;background-color:#fff;display:flex;flex-wrap:nowrap;flex-direction:row-reverse;position:relative}.rcsbFvSplitPanel{height:100%;border-left:3px solid #b5cbe2;position:absolute;cursor:ew-resize;opacity:0}.rcsbFvSubtitle{font-size:16px;font-family:inherit;line-height:1.1;color:inherit;margin-bottom:15px}.rcsbFvTitle{font-size:24px;font-family:inherit;font-weight:bold;line-height:1.1;color:inherit;margin-bottom:15px}.rcsbFvClose{position:absolute;right:10px;top:5px;cursor:pointer}`, "",{"version":3,"sources":["webpack://./lib/scss/RcsbFvStyle.module.scss"],"names":[],"mappings":"AAAA,sBACE,0BAAA,CACA,wBAAA,CACA,uBAAA,CACA,qBAAA,CACA,oBAAA,CACA,gBAAA,CAGF,YACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CAGF,YACE,WAAA,CAGF,cACE,qBAAA,CAGF,YACE,UAAA,CACA,WAAA,CACA,qBAAA,CACA,YAAA,CACA,gBAAA,CACA,0BAAA,CACA,iBAAA,CAGF,kBACE,WAAA,CACA,6BAAA,CACA,iBAAA,CACA,gBAAA,CACA,SAAA,CAGF,gBACE,cAAA,CACA,mBAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAGF,aACE,cAAA,CACA,mBAAA,CACA,gBAAA,CACA,eAAA,CACA,aAAA,CACA,kBAAA,CAGF,aACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
export var disableTextSelection = `disableTextSelection`;
export var fullScreen = `fullScreen`;
export var fullHeight = `fullHeight`;
export var rcsbFvMain = `rcsbFvMain`;
export var rcsbFvSplitPanel = `rcsbFvSplitPanel`;
export var rcsbFvSubtitle = `rcsbFvSubtitle`;
export var rcsbFvTitle = `rcsbFvTitle`;
export var rcsbFvClose = `rcsbFvClose`;
export default ___CSS_LOADER_EXPORT___;
